import React from 'react'

import Container from 'react-bootstrap/Container'

import './section.scss'

interface Props {
  children: React.ReactNode
  bg: string
  variant: string
  className: string
}

const Section = ({ children, variant, className }: Props) => {
  const classes = [variant, className]
  return (
    <section className={classes.filter(Boolean).join(' ').trim()}>
      <Container fluid="md">{children}</Container>
    </section>
  )
}

export default Section
