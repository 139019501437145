import React from 'react'
import classNames from 'classnames'

import Footer from './footer'
import Header from './header'

interface Props {
  children: React.ReactNode
  location: Location
  bg: string
  variant: string
  className: string
}

const Wrapper = ({ children, location, variant, className }: Props) => {
  const classes = [variant, className]
  return (
    <div id="wrapper" className={classNames(classes)}>
      <header>
        <a id="home" />
        <Header location={location} variant={variant} />
      </header>
      <main>{children}</main>
      {location?.pathname === '/' && (
        <footer className="mt-auto bg-light">
          <Footer variant={variant} />
        </footer>
      )}
    </div>
  )
}

export default Wrapper
