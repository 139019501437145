import React from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Section from 'components/section'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

import { useSiteMetadata } from 'utils/use-site-metadata'

interface Props {
  location: Location
}

const NotFound = ({ location }: Props) => {
  const { siteTitleShort } = useSiteMetadata()
  return (
    <>
      <Meta title="Not Found" />
      <Wrapper location={location} className="d-flex w-100 h-100 mx-auto flex-column">
        <Section className="py-5">
          <Row className="justify-content-center align-items-center text-center">
            <Col xs={12}>
              <h2>{`${siteTitleShort ?? siteTitle}`}</h2>
              <p className="fs-4">Page Not Available</p>
              <p className="fs-5 my-5">Sorry, can&apos;t find it.</p>
              <Row className="g-2 justify-content-center text-nowrap">
                <Col className="flex-grow-0">
                  <Button href={'http://artlab.eco/'} variant="outline-primary" size="lg">
                    Return to Eco ArtLab
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </Wrapper>
    </>
  )
}

export default NotFound
